import React, {useState} from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import PageHeader from '../components/page-header'
import Container from '../components/container'
import StoryBox from '../components/story-box'
import Button from '../components/button'
import SEO from '../components/seo'

import Layout from '../containers/layout'

import FocusSection from '../components/ourFocus'

import { ArrowLeft, ArrowRight } from "react-feather";
import Swiper from "react-id-swiper";

import { mapEdgesToNodes, filterOutDocsWithoutSlugs, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

export const query = graphql`
  query ServicesPageQuery {
    page: sanityServices(_id: { regex: "/(drafts.|)services/" }) {
      id
      title
      image {
        asset {
          _id
        }
      }
      _rawBody
      service {
        title
        description
        image {
          asset {
            _id
          }
        }
      }
      showStories
      storiesTitle
      storiesDescription
      mainCTA
      mainCTAURL
      keywords
      seoDes
      
    }

    stories: allSanityStory(limit: 4, sort: { fields: [publishedAt], order: ASC }) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          logo {
            asset {
              _id
            }
            alt
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ParagraphClass = 'inline-block relative w-full text-sm md:text-base text-white mb-12'
const HeadingClass = 'inline-block relative w-full px-10 text-3xl md:text-3xl my-6 text-center font-bold'

const ServicesPage = props => {

  const [opened, setOpened] = useState('')
  const [swiper, setSwiper] = useState(null);

  const openAccordian = (title) => {
  
      if (opened === title) {
        return setOpened(0)
      }
      console.log(opened)
      setOpened(title)
      console.log(opened)
  }

  const params = {
    rebuildOnUpdate: false,
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
      640: {
        slidesPerView: 3,
        spaceBetween: 32,
      },
    },
    slideClass: "swiper-slide",
    getSwiper: setSwiper,
    autoplay: true,
  };
  
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
  
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page
  const storyNodes = (data || {}).stories
    ? mapEdgesToNodes(data.stories).filter(filterOutDocsWithoutSlugs)
    : []

  if (!page) {
    throw new Error(
      'Missing "Services" page data. Open the studio at http://localhost:3333 and add "Services" page data and restart the development server.'
    )
  }

  const Service = ({ title, description, image }) => {

    const [active, setActive] = useState(false)

    const OpenContent = () => {
      if (active === true) {
        setActive(false)
      } else {
        setActive(true)
      }

    }
    
    return ( <div className="inline-block w-full md:flex items-center align-middle justify-between text-left ">
      <div className="inline-block relative w-full mb-4">
        <div  className="inline-block w-full mr-0 md:mr-16">
          <div onClick={() => OpenContent()} className="flex pb-2 justify-between items-center relative w-full accordian-title mb-2">
            <strong  className={`inline-block relative w-full text-xl md:text-2xl ${active === true ? 'orange' : 'text-white'}`}>{title}</strong>
            <img className={`w-4 h-4 accordianX ${active === true ? 'show' : ''}`} src="accordianStatus.svg"></img>
          </div>
          <p
            className={`${ParagraphClass} w-full text-left text-white accordian-content ${active === true ? 'show' : 'closed'}`}
            style={{
              whiteSpace: 'pre-line'
            }}
          >
            {description}
          </p>
        </div>
      </div>
      {/* <div className="inline-block relative w-full md:w-64">
        {image && image.asset && (
          <img
            className="inline-block relative w-full mb-4"
            src={imageUrlFor(buildImageObj(image))
              .width(600)
              .height(600)
              .fit('crop')
              .url()}
          />
        )}
      </div> */}
    </div>
  )}

  return (
    <Layout>
      <SEO 
        title={page.title} 
        description={page.seoDes}
        keywords={page.keywords}
      />
      <Container>
        <div className="inline-block relative  w-full  text-left md:text-center">
          <div className="inline-block pt-16  md:pt-32 hill-background relative w-full">
            <PageHeader
              textAlign="text-left"
              pageClass="handle-list services"
              title={page.title || `Mission & values`}
              // image={page.image ? page.image : undefined}
              // body={page._rawBody ? page._rawBody : undefined}
              full
            />
            <FocusSection />
            {/* Services list */}
            <div className="w-full light-green">

          
            {page.service && page.service.length > 0 ? (
              <div className="inline-block  w-full relative py-20 px-10 lg:px-40">
                {page.service.map((service, index) => (
                  <Service
                    title={service.title}
                    description={service.description}
                    image={service.image && service.image.asset ? service.image : null}
                    key={`service-${index}`}
                  />
                ))}
              </div>
            ) : null}
            {/* Stories */}
          </div>
          <div className="inline-block relative dark-green w-full  py-10">
            {page.showStories ? (
              <div>
                {page.storiesTitle ? <h2 className={` mx-auto text-3xl md:text-5xl my-6 text-center font-bold border-b-4 w-9/12 lg:w-6/12 leading-tight`} >{page.storiesTitle}</h2> : null}
                {page.storiesDescription ? (
                  <p className={`${ParagraphClass} text-center`}>{page.storiesDescription}</p>
                ) : null}
                <div className="flex px-4 mb-8 relative items-center w-full">
                 
                    <div className="h-full relative flex items-center justify-center w-1/12">

                    <ArrowLeft 
                      className="orange arrowClass"
                      onClick={goPrev}
                    />
                    </div>
                    <div className="h-full mx-auto w-9/12 lg:w-10/12 ">
                      
                  <Swiper {...params}>
                    {storyNodes &&
                      storyNodes.length > 0 &&
                      storyNodes.map((story, index) => (
                        <StoryBox story={story} key={`story-${index}`} />
                      ))}
                  </Swiper>
                      </div>
                 <div className="h-full relative flex items-center justify-center w-1/12">

                    <ArrowRight onClick={goNext} className="orange arrowClass"/>
                 </div>
                </div>
            </div>
            ) : null}
            {/* CTA */}
            {page.mainCTA ? (
              <div className="flex w-full justify-center pb-20 px-20  relative">
                {/* page.mainCTA page.mainCTAURL */}
                <Button outlined text={page.mainCTA} to={page.mainCTAURL} />
              </div>
            ) : null}
            </div>
            </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ServicesPage
